import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-decoration: none;
    border-radius: .3em;
    padding: .4em 1em;
    height: 40px;
    gap: .5em;
    cursor: pointer;
    color: black;
    z-index: 1;
    font-size: 1.03em;
    background-color: #B9944A;
    transition: background-color .2s ease-in-out;

    &.disabled {
        animation-delay: 1000ms;
        animation: disabled ease 800ms forwards;

        &:hover {
            &:after {
                inset: 0;
            }
        }
    }

    .tooltip {
        position: absolute;
        top: -130%;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
        min-width: 140px;
        font-size: 12px;
        padding: 0.4em;
        z-index: 2;
        opacity: 0;
        border-radius: 0.6em;
        transition: opacity 200ms ease, transform 200ms ease;
        pointer-events: none;
        transform: translateY(8px) scale(0.8);

        &.active {
            opacity: 1;
            transform: translateY(0) scale(1);
        }
    }

    &:after {
        transition: inset .1s linear;
        position: absolute;
        content: '';
        inset: 0;
        border-radius: inherit;
        z-index: -1;
        background-color: #B9944A;
    }

    .icon {
        width: 22px;
        height: 22px;
        margin: 0 0 2px 0;

        &-check {
            width: 1.2em;
        }
    }
    
    .text {
        color: #FFFFFF;
        overflow: hidden;
        white-space: nowrap;
    }

   @media (pointer: fine) {
       &:hover {
           &:after {
               inset: -3px;
           }
       }
   }

    @keyframes disabled {
        90% {
            background-color: #fff;
            filter: grayscale(0);
            opacity: 1;
        }
        100% {
            background-color: #cbcbcb;
            filter: grayscale(1);
            opacity: 0.8;
        }
    }

    @media all and (max-width: ${variables.tabletS}) {
        height: 36px;
    }
`;
