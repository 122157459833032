import styled from '@emotion/styled';

import vars from 'theme/styles/vars';

export default styled.div`
	.instalments {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 0.5em;
		padding: 0.5em;
		padding-top: 2em;

		&__logo {
			max-width: 90px;
			height: auto;
		}

		&__label {
			font-weight: 600;

			&--red {
				color: #ca0100;
			}
		}

		&__button {
			.button {
				padding: 0.7em 1.7em;
			}
		}

		&__text-button {
			font-size: 0.9em;
			text-decoration: underline;
			transition: color 0.1s ease-in-out, transform 0.1s ease-in-out;
			cursor: pointer;

			&:hover {
				transform: scale(1.05);
			}
		}

		//modyfiers
		&--top-border {
			border-top: 1px solid #b9b6b6;
		}

		&--only-logo-button {
			width: max-content;
			height: 100%;
			padding: 0;
			cursor: pointer;
			transition: transform 0.1s ease-in-out, opacity 0.1s ease-in-out;

			&:hover {
				transform: scale(1.1);
				opacity: 0.8;
			}
		}

		&--logo-block {
			.instalments {
				&__logo {
					max-width: 147px;
				}
			}

			&:hover {
				transform: scale(1.04);
			}
		}

		&--layout-cart {
			padding: 1em 1em 0 1em;

			.instalments {
				&__logo {
					max-width: 55px;
				}

				&__label {
					font-size: 0.9em;
				}
			}
		}
	}

	@media all and (max-width: ${vars.desktopS}) {
		.instalments {
			gap: 0.2em;

			&__logo {
				max-width: 70px;
			}

			&--only-logo-button {
				.instalments {
					&__logo {
						max-width: 90px;
					}
				}
			}

			&--logo-block {
				.instalments {
					&__logo {
						max-width: 147px;
					}
				}
			}

			&--layout-cart {
				.instalments {
					&__label,
					&__text-button {
						font-size: 1em;
					}
				}
			}
		}
	}

	@media all and (max-width: ${vars.tabletL}) {
		.instalments {
			flex-flow: row wrap;
			gap: 0.5em;

			&--layout-default {
				.instalments {
					&__logo {
						width: 30%;
						max-width: 26%;
					}

					&__label {
						width: 68%;
						font-size: 1.25em;
					}

					&__button {
						margin: 1em 0 0 0;
						width: 100%;
						font-size: 1.4em;

						.button {
							padding: 0.8em 3em;
						}
					}
				}
			}

			&--only-logo-button {
				.instalments {
					&__logo {
						width: unset;
						max-width: 90px;
					}
				}
			}

			&--logo-block {
				.instalments {
					&__logo {
						width: unset;
						max-width: 147px;
					}
				}
			}
		}
	}

	@media all and (max-width: ${vars.mobileSM}) {
		.instalments {
			&__label {
				font-size: 1.2em;
			}

			&--only-logo-button {
				.instalments {
					&__logo {
						max-width: 90px;
					}
				}
			}

			&--logo-block {
				.instalments {
					&__logo {
						max-width: 147px;
					}
				}
			}

			&__button {
				font-size: 1.2em;
			}
		}
	}
`;
