import { FunctionComponent } from 'react';

import Tracking from 'services/Tracking';

import StyledComponent from './styles';
import { Props } from './types';

const PublicLayoutOfferBuyNowButton: FunctionComponent<Props> = ({ onClick, label }) => {
    return (
        <StyledComponent
            className="public-layout-offer-buy-now-button"
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                Tracking.eventBuyNow();
                onClick(e);
            }}
        >
            <img
                className="icon"
                src="/images/home/buy-now-new.svg"
                loading="lazy"
                alt={label}
                width="20px"
                height="20px"
            />
            <p className="text">{label}</p>
        </StyledComponent>
    );
};

export default PublicLayoutOfferBuyNowButton;
