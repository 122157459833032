import Component from './component';

export enum LogoTypes {
    Default = 'default',
    Block = 'block',
}

export enum Layouts {
    Default = 'default',
    Cart = 'cart',
}

export interface Props {
    price: number;
    topBorder?: boolean;
    onlyLogoButton?: boolean;
    logoType?: LogoTypes;
    layout?: Layouts;
}

Component.defaultProps = {
    price: 1,
    topBorder: false,
    onlyLogoButton: false,
    logoType: LogoTypes.Default,
    layout: Layouts.Default,
};

export default Component;
