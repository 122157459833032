import { FunctionComponent, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import getConfig from 'next/config';
import classnames from 'classnames';

import StyledComponent from './styles';
import { Props } from './types';

const PublicLayoutOfferAddToCartButton: FunctionComponent<Props> = ({ onClick, disabled, label }) => {
    const timeoutRef = useRef<number | undefined>();

    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [addingToCart, setAddingToCart] = useState<boolean>(false);

    const { publicRuntimeConfig } = getConfig();
    let translations: string | null;

    const onMouseEnter = () => setShowTooltip(true);
    const onMouseLeave = () => setShowTooltip(false);

    if (publicRuntimeConfig.APP_LOCALE === 'pl') translations = 'ta oferta specjalna, jest już w koszyku';
    if (publicRuntimeConfig.APP_LOCALE === 'en') translations = 'this special offer is already in cart';

    const onAdd = async (e: React.MouseEvent<HTMLDivElement>) => {
        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
            timeoutRef.current = undefined;
        }

        if(disabled) return;

        setAddingToCart(true);
        return onClick?.(e)
            .then(() => {
                let message = '';
                if (publicRuntimeConfig.APP_LOCALE === 'pl') message = 'Oferta dodana do koszyka';
                if (publicRuntimeConfig.APP_LOCALE === 'en') message = 'Offer has been added to cart';

                toast.success(message);
            })
            .catch(() => {
                let message = '';
                if (publicRuntimeConfig.APP_LOCALE === 'pl') message = 'Nie udało nam się dodać wybranej oferty do koszyka';
                if (publicRuntimeConfig.APP_LOCALE === 'en') message = 'We were unable to add the selected offer to the cart';

                toast.error(message);
            })
            .finally(() => {
                timeoutRef.current = window.setTimeout(() => {
                    setAddingToCart(false);
                }, 750);
            });
    };

    return (
        <StyledComponent
            className={classnames(
                'public-layout-offer-add-to-cart-button',
                { disabled }
            )}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => onAdd(e)}
            onMouseEnter={() => disabled ? onMouseEnter() : null}
            onMouseLeave={() => disabled ? onMouseLeave() : null}
        >
            <div
                className={classnames(
                    'tooltip',
                    { active: showTooltip }
                )}
            >
                {translations}
            </div>
            {addingToCart ? (
                <img
                    className="icon icon-check"
                    src="/images/cart/check-white.svg"
                    alt="Dodaj do koszyka"
                    width="20px"
                    height="20px"
                    loading="lazy"
                />
            ) : (
                <img
                    className="icon"
                    src="/images/home/cart-white.svg"
                    alt="Dodaj do koszyka"
                    width="20px"
                    height="20px"
                    loading="lazy"
                />
            )}
            <p className="text">{label}</p>
        </StyledComponent>
    );
};

export default PublicLayoutOfferAddToCartButton;