import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.063em;
    gap: 0.5em;
    padding: .4em 1em;
    height: 40px;
    text-decoration: none;
    border-radius: .3em;
    cursor: pointer;
    

    &:after {
        content: '';
        transition: inset .1s linear;
        border: 2px solid #B9944A;
        position: absolute;
        inset: 0;
        border-radius: inherit;
        z-index: -1;
    }

    .icon {
        max-width: 20px;
    }

    .text {
        white-space: nowrap;
        color: #B9944A;
    }

    @media (pointer: fine) {
       &:hover {
           &:after {
               inset: -3px;
           }
       }
   }

    @media all and (max-width: ${variables.tabletS}) {
        height: 36px;
    }
`;
