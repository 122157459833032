import { FunctionComponent, useState } from 'react';
import dynamic from 'next/dynamic';
import classnames from 'classnames';

import { changeToCent } from 'utils/math';

import Button, { ButtonStyles } from 'components/layout/Button';

import { Layouts, LogoTypes, Props } from './index';
import StyledComponent from './styles';

const Portal = dynamic(() => import('components/modules/public/layout/modals/Portal'), { ssr: false });
const ProductBoxP24InstalmentsModal = dynamic(() => import('components/modules/public/products/ProductBoxP24InstalmentsModal'), { ssr: false });

const LayoutP24Instalments: FunctionComponent<Props> = ({ price, logoType, topBorder, onlyLogoButton, layout }) => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const priceInCent = changeToCent(price);

    const logos = {
        [LogoTypes.Default]: '/images/instalments.png',
        [LogoTypes.Block]: '/images/instalments-block.png',
    };

    return (
        <StyledComponent className={classnames('layout-p24-instalments')}>
            <div
                onClick={() => setModalVisible(true)}
                className={classnames(
                    'instalments',
                    `instalments--logo-${logoType}`,
                    `instalments--layout-${layout}`,
                    {
                        'instalments--top-border': topBorder && !onlyLogoButton,
                        'instalments--only-logo-button': onlyLogoButton,
                    })}
            >
                {layout === Layouts.Default && (
                    <>
                        <img
                            className="instalments__logo"
                            src={logos[logoType]}
                            alt="instalments"
                            aria-hidden="true"
                        />
                        {!onlyLogoButton && (
                            <>
                                <p className="instalments__label">
                                Rozłóż płatność na {''}
                                    <span className="instalments__label--red">
                                raty 0%
                                    </span>
                                </p>
                                <Button
                                    className="instalments__button"
                                    style={ButtonStyles.Green}
                                    onClick={() => setModalVisible(true)}
                                >
                            Oblicz raty
                                </Button>
                            </>
                        )}

                    </>
                )}
                {layout === Layouts.Cart && (
                    <>
                        <p className="instalments__label">
                            Rozłóż płatność na {''}
                            <span className="instalments__label--red">
                            raty 0%
                            </span>
                        </p>
                        <img
                            className="instalments__logo"
                            src={logos[logoType]}
                            alt="instalments"
                            aria-hidden="true"
                        />
                        <p
                            className="instalments__text-button"
                            onClick={() => setModalVisible(true)}
                        >
                            Oblicz ratę
                        </p>
                    </>
                )}
            </div>

            {modalVisible && (
                <Portal>
                    <ProductBoxP24InstalmentsModal
                        priceInCent={priceInCent}
                        onClose={() => setModalVisible(false)}
                    />
                </Portal>
            )}
        </StyledComponent>
    );
};

export default LayoutP24Instalments;
